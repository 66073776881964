import * as React from 'react';

import { FEATURE_SITECORE_HOMEPAGE, isEnabled } from '@utils/features';

import SitecoreMain from '@domui-sitecore/wrappers/SitecoreHomepage';
import BackendIframe from '@layouts/backend-iframe';

const IndexPage: React.FC = () => {
  return isEnabled(FEATURE_SITECORE_HOMEPAGE) ? (
    <SitecoreMain />
  ) : (
    <BackendIframe />
  );
};

export default IndexPage;
